import React from "react"
import PropTypes from "prop-types"
// Components
import Img from "gatsby-image"
import { Link, graphql } from "gatsby"
import SEOTAG from '../components/seotag'
import Layout from '../components/Layout'
import { Card, Row, Col } from 'react-bootstrap'
import { SlugLink } from '../components/utils'

const Tags = ({ location, pageContext, data }) => {
  //console.log(pageContext, data);
  const siteTitle = data.site.siteMetadata.title
  const domainName = data.site.siteMetadata.domainName
  const shortName = data.site.siteMetadata.shortName
  const { tag } = pageContext
  const { edges, totalCount } = data.allMarkdownRemark
  let fullTag = tag;
  if (edges && edges.length > 0) {
    fullTag = edges[0].node.frontmatter.tags.filter(x => SlugLink(x) === tag);
  }
  const tagHeader = `${totalCount} post${
    totalCount === 1 ? "" : "s"
    } "${fullTag}"`
  // if (location != null) {
  //   if (location.href != null && location.href.endsWith('/')) {
  //     window.location.href = window.location.href.substring(0, window.location.href.length - 1);
  //   }
  // }

  return (
    <Layout location={location} title={`${siteTitle}`}>
      <SEOTAG
        title={`${fullTag} - ${shortName}`}
        description= {`${fullTag} - ${siteTitle}`}
      // keywords={[`${fullTag}`]}
      />
        <div className="row feature-list feature-list-sm">
        <div className="col-12 text-center"><h1>{tagHeader}</h1></div>
          {edges.map(({ node }) => {
            let featuredImgFluid = node.frontmatter.cover.childImageSharp.fluid
            const title = node.frontmatter.title || node.fields.slug
            return (
              <div class="col-md-4 post-list-h">
                            <div class="feature-12">
                            <Link to={node.fields.slug} title={title}>
                                <Img fluid={featuredImgFluid} objectFit="cover"
                                    objectPosition="50% 50%"
                                    alt={title} title={title} className="card-img-top" />
                                </Link>
                                <div class="feature__body">
                                    <h4><Link to={node.fields.slug} title={title}>{title}</Link></h4>
                                    <p className="lead" dangerouslySetInnerHTML={{ __html: node.excerpt }} />
                                    <Link to={node.fields.slug} title={title} className="text-right">
                                        Chi tiết
                                    </Link>
                                </div>
                            </div>
                        </div>
            )
          })}
      </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <Link to="/tags" className="btn-alltags">Tất cả từ khóa</Link>
          </div>
        </div>
    </Layout>
  )
}
Tags.propTypes = {
  pageContext: PropTypes.shape({
    tag: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export const pageQuery = graphql`
  query TagPage($tag: String) {
    site {
      siteMetadata {
        title
        domainName
        shortName
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
      filter: { fields: { tags: { in: [$tag] } },frontmatter: {date: {lt: "new Date()"}} }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
            tags
          }
          excerpt
          timeToRead
          frontmatter {
            date(formatString: "DD MMMM, YYYY")
            title
            tags
            category
            cover {
                childImageSharp {
                  fluid(maxWidth: 800) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
          }
        }
      }
    }
  }
`
export default Tags